import { Blogs } from '../assets/blogs';
import { Avatars } from '../assets/avatars';
import { v4 as uuidv4 } from 'uuid';

export const blogs = [
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
  {
    id: uuidv4(),
    title: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lore',
    description: 'Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    author: [{ name: 'Margareth White', role: 'Barrister', avatar: Avatars._02 }],
    created_at: '02 december 2022',
    read_duration: 3,
    tags: ['Conveyancing', 'Legal long title', 'Conveyancing'],
  },
];

export const articles = [
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._01,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._02,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._03,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._04,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._05,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._06,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._07,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._08,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._09,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._10,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._11,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
  {
    id: uuidv4(),
    title: 'Label',
    description: 'Complement Complement',
    cover: Blogs._12,
    author: [{ name: 'Richard Cammish', role: 'Solicitor', avatar: Avatars._01 }],
    tags: ['Commercial', 'Conveyancing', 'Legal long title'],
    created_at: '7th Jan, 22',
  },
];

export const tags = [
  'Commercial',
  'Employment',
  'Healthcare',
  'Data',
  'Probate',
  'Family',
  'Insurance',
  'Technology',
  'Advice',
  'DocReview',
];

export const sectors = [
  {
    name: 'Aviation',
    count: 20,
  },
  {
    name: 'Energy',
    count: 20,
  },
  {
    name: 'Gas and Oil',
    count: 20,
  },
  {
    name: 'Advertising',
    count: 20,
  },
  {
    name: 'Transport',
    count: 20,
  },
];

export const categories = [
  {
    name: 'Category 1',
    count: 12,
  },
  {
    name: 'Category 2',
    count: 20,
  },
  {
    name: 'Category 3',
    count: '28',
  },
  {
    name: 'Category 4',
    count: 36,
  },
];
