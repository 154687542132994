import React from 'react';

const ComboBox = () => {
  return (
    <div class="relative flex items-center gap-4 w-[250px] h-[45px] px-4 py-[12px] rounded-[8px] border border-[#F4F4F4] bg-white shadow-[0px_0.4px_2px_0px_rgba(0,0,0,0.25)]">
        <select class="w-full bg-transparent text-[#00323D] text-[16px] font-medium leading-[24px] tracking-[0.4px] outline-none appearance-none">
            <option value="option1">Location</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
        </select>
        <div class="w-[12px] h-[12px] flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path d="M6 9.5L0 3.5H12L6 9.5Z" fill="#37A2BA"/>
            </svg>
        </div>
    </div>

  );
};

export default ComboBox;
