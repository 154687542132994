import React from 'react';
import FaqCard from '../ui-components/FaqCard';

function FaqSection() {

    return (
        <div className="w-full flex flex-col px-20 py-[50px]">
            <div className="text-left text-[#020251] font-dm-sans text-[40px] font-bold leading-normal tracking-normal" style={{ fontFeatureSettings: "'liga' off, 'clig' off" }}>
                FAQ
            </div>
            <div className = "flex flex-row">
                <div className = "flex flex-col w-full mr-10 pr-10">
                    <FaqCard title = "What does a solicitor do?" />
                    <FaqCard title = "When should I hire a solicitor?" />
                    <FaqCard title = "Can solicitors represent me in court?" />
                    <FaqCard title = "How do solicitors charge for their services?" />
                    <FaqCard title = "Can I consult a solicitor for legal advice without going to court?" />
                </div>

            </div>
        </div>
    );
}

export default FaqSection;
