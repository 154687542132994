import React from 'react';
import { Avatars } from '../assets/avatars'
import { flags } from '../assets/flags'
import { BsInfoCircle, BsX, BsXLg } from 'react-icons/bs'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'

function Header() {
  return (
    // <div className="absolute top-[0px] left-0 w-full max-w-[1920px] h-[82px] bg-[#9FB7D7] border border-white/12 flex flex-col items-start p-[20px_20px_20px_50px] gap-[40px] box-border">
    //   <h1 className="text-blue-custom font-sans font-bold text-[32px] leading-[42px]">
    //     Get a barrister
    //   </h1>
    // </div>

    <header className="bg-[#CCE0DD]">
       <nav className='navbar px-[2.5rem] py-[1.25rem] flex flex-row'>
          <span className="text-blue-custom font-sans font-bold text-[32px] leading-[42px]">Book a Solicitor</span>

          <div className='navbar-action ml-auto flex flex-wrap items-center gap-8'>
            <Link to='mailto:customerservice@legalutopia.co.uk?subject=Customer%20Support'>
              <BsInfoCircle className='inline-block mr-1' /> Customer support
            </Link>
            <img src={flags.UK} alt='' />
          </div>

       </nav>
    </header>


  );
}

export default Header;
