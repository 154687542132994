import React, { useState } from 'react';
import { articles, blogs, categories, sectors, tags } from '../data/ArticlesData'
import { LatestCard, LatestCard2, MostViewedCard, PostCard } from '../ui-components/ArticlesCard'

function ArticlesBlogs() {
    const [filteredData, setFilteredData] = useState(articles)
    const [articleToShow, setEpisodeToShow] = useState(6)
    // Define the category data with image paths and titles
    return (
        <div className="flex flex-col ml-20 py-[50px]">
            <div className="text-left text-[#020251] font-dm-sans text-[40px] font-bold leading-normal tracking-normal" style={{ fontFeatureSettings: "'liga' off, 'clig' off" }}>
                Articles and blogs related
            </div>

            <div className="flex flex-wrap w-full">
                    <LatestCard data={blogs} />
            </div>

            <div className="rounded-[20px] border-[0.6px] border-[#B8E0DB] bg-[#F1FFFD] flex flex-col items-center justify-center mx-10">
                <div className="text-[#020251] text-center font-dm-sans text-[24px] font-bold leading-tight tracking-custom my-1">
                    Do you have questions?
                </div>

                <div className="text-[#6E7785] text-center font-dm-sans text-xl font-medium leading-[26px] tracking-[0.4px] mb-5">
                    Check our FAQ...
                </div>

                <div className="rounded-[6px] bg-[#020251] shadow-custom text-white text-center font-medium text-[16px] leading-[20px] mb-[40px] px-3 py-1.5">
                    Join
                </div>
            </div>

            <div className="flex flex-wrap w-full">
                    <LatestCard2 data={blogs} />
            </div>
        </div>
    );
}

export default ArticlesBlogs;
