import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Label from './ui-components/Label';
import Button from './ui-components/Button';
import Input from './ui-components/Input';
import './App.css';
import RangeSlider from './ui-components/RangeSlider';

import SearchSection from './pages/SearchSection';
import SearchedSection from './pages/SearchedSection';
import BannerSection from './pages/BannerSection';
import ArticleBlogs from './pages/ArticlesBlogs';
import FaqSection from './pages/FaqSection';

import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App h-full">
        <Header />

        <div className="flex flex-col">
          <SearchSection/>

          <div className = "flex flex-row">
            <ArticleBlogs/>
            <div className = "flex flex-col">
              <div className="flex flex-col items-start gap-10 mx-auto mt-10 px-20 py-10 rounded-sm border-2 border-white/12 bg-[#00323D] w-2/3">
                  <div className="text-white font-medium text-2xl leading-6 tracking-[0.4px] text-center w-full">
                    Why use a solicitor instead of a barrister?
                  </div>
                  <ul className="list-disc text-[#ADF1E8] font-medium text-base leading-6 tracking-[0.4px] text-left self-stretch">
                      <li className="mb-[20px] self-stretch">Solicitors serve as your first point of contact for legal matters and provide comprehensive support throughout your case. <span className="text-[#CCE0DD]">They handle a wide range of legal services, </span>including drafting documents, negotiating agreements, and providing ongoing legal advice tailored to your needs.</li>

                      <li className="mb-[20px] self-stretch">Solicitors are known for their <span className="text-[#CCE0DD]">strong client relationships and consistent communication,</span> ensuring you are always informed and updated about your case progress without needing to follow up.</li>

                      <li className="mb-[20px] self-stretch">While solicitors can represent you in lower courts, they also work closely with barristers when your case requires specialised courtroom advocacy. They can <span className="text-[#CCE0DD]">prepare your case, gather evidence, and build a solid strategy </span>to present to a barrister if needed.</li>

                      <li className="mb-[20px] self-stretch">Additionally, solicitors typically offer more <span className="text-[#CCE0DD]">flexible fee structures,</span> often providing fixed rates for specific services, which can help you manage costs effectively. They will<span className="text-[#CCE0DD]"> discuss the costs upfront,</span> so you can make informed decisions about your legal representation.</li>
                  </ul>
              </div>
              <SearchedSection/>
              <FaqSection/>
            </div>
          </div>
          {/* <BannerSection/>
          <SearchedSection/>
          
          <FaqSection/> */}
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
