import React from 'react';

const Badge = ({ color, title }) => {
  return <div className={`badge badge-${color}`}>{title}</div>;
};

const Avatar = ({
  name,
  caption,
  picture,
  className,
  sx,
  url,
  urlName,
  tx,
}) => {
  return (
    <div className={`avatar flex ${className}`}>
      <img
        className="br-50"
        style={{
          background: `url(${picture}) lightgray 50% / cover no-repeat`,
          height: `${sx ? sx + 'px' : '36px'}`,
          width: `${sx ? sx + 'px' : '36px'}`,
        }}
      />
      <div className="ml-2">
        {name && <h4 className={`text-[${tx ? tx : '12'}px]`}>{name}</h4>}
        {caption && (
          <p className={`text-[${tx ? tx - 2 : '12'}px]`}>{caption}</p>
        )}
        {url && (
          <a className="fs-xs" href={url}>
            {urlName}
          </a>
        )}
      </div>
    </div>
  );
};

export { Badge, Avatar };
