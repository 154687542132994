import React from 'react';

function SearchBar() {
  return (
    <div className="w-[490px] h-[128px] bg-[#F0F0F0] border-4 border-white rounded-[20px] flex items-center p-[18px] gap-[10px] backdrop-blur-[100px] box-border">
      <div className="flex flex-col items-start bg-white rounded-[16px] p-[30px] gap-[28px] w-[454px] h-[92px]">
            <input 
            type="text" 
            placeholder="Use tags to help your search" 
            className="bg-transparent outline-none text-[#25395C] placeholder-[#25395C] font-sans font-bold text-[24px] leading-[26px] tracking-[0.4px] w-full"
            />
      </div>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6654 15.9986L15.9987 21.3319L17.8654 19.4653L15.732 17.3319H21.332V14.6653H15.732L17.8654 12.5319L15.9987 10.6653L10.6654 15.9986ZM2.66537 15.9986C2.66537 14.1542 3.01537 12.4208 3.71537 10.7986C4.41537 9.17639 5.36537 7.76528 6.56537 6.56528C7.76537 5.36528 9.17648 4.41528 10.7987 3.71528C12.4209 3.01528 14.1543 2.66528 15.9987 2.66528C17.8431 2.66528 19.5765 3.01528 21.1987 3.71528C22.8209 4.41528 24.232 5.36528 25.432 6.56528C26.632 7.76528 27.582 9.17639 28.282 10.7986C28.982 12.4208 29.332 14.1542 29.332 15.9986C29.332 17.8431 28.982 19.5764 28.282 21.1986C27.582 22.8208 26.632 24.2319 25.432 25.4319C24.232 26.6319 22.8209 27.5819 21.1987 28.2819C19.5765 28.9819 17.8431 29.3319 15.9987 29.3319C14.1543 29.3319 12.4209 28.9819 10.7987 28.2819C9.17648 27.5819 7.76537 26.6319 6.56537 25.4319C5.36537 24.2319 4.41537 22.8208 3.71537 21.1986C3.01537 19.5764 2.66537 17.8431 2.66537 15.9986ZM5.33203 15.9986C5.33203 18.9764 6.36537 21.4986 8.43203 23.5653C10.4987 25.6319 13.0209 26.6653 15.9987 26.6653C18.9765 26.6653 21.4987 25.6319 23.5654 23.5653C25.632 21.4986 26.6654 18.9764 26.6654 15.9986C26.6654 13.0208 25.632 10.4986 23.5654 8.43195C21.4987 6.36528 18.9765 5.33195 15.9987 5.33195C13.0209 5.33195 10.4987 6.36528 8.43203 8.43195C6.36537 10.4986 5.33203 13.0208 5.33203 15.9986Z" fill="url(#paint0_linear_13039_10397)"/>
            <defs>
            <linearGradient id="paint0_linear_13039_10397" x1="28.998" y1="15.9973" x2="2.99805" y2="15.9973" gradientUnits="userSpaceOnUse">
            <stop stop-color="#25395C"/>
            <stop offset="1" stop-color="#66C1D7"/>
            </linearGradient>
            </defs>
        </svg>
    </div>
   );
}

export default SearchBar;