import React from 'react';
import { Badge, Avatar } from '../components/Utils';
import { BsCalendar, BsClock } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import '../style/style.css';
export const MostViewedCard = ({ data }) => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/article/${id}`);
  };

  return (
    <>
      {data &&
        data.slice(0, 2).map((blog, idx) => (
          <div
            key={idx}
            className="my-2"
            onClick={() => handleCardClick(blog.id)}
            style={{ cursor: 'pointer' }}
          >
            <div className="flex gap-2">
              {blog.tags.map((tag, idx) => (
                <Badge key={idx} title={tag} color="primary" />
              ))}
            </div>
            <h3 className="my-2 h3">{blog.title}</h3>
            <div className="flex items-center flex-wrap">
              <Avatar
                picture={blog.author[0].avatar}
                name={blog.author[0].name}
                caption={blog.author[0].role}
                className="br-lg-theme"
              />
              <div className="flex items-center br-lg-theme self-stretch pl-lg-2">
                <BsCalendar className="mr-2" /> {blog.created_at}
              </div>
              <div className="flex items-center pl-lg-2">
                <BsClock className="mr-2" /> {blog.read_duration} min to read
              </div>
            </div>
            <p className="text-muted my-3">{blog.description}</p>
          </div>
        ))}
    </>
  );
};

export const LatestCard2 = ({ data }) => {
  return (
      <div className="my-1 mx-2 flex flex-col gap-4">
        {data &&
          data.slice(0, 6).map((blog, idx) => (
            <div
              className={`card bg-white w-full ${
                idx % 4 === 0 ? 'ml-0' : 'ml-auto' // First item in a row
              } ${
                (idx + 1) % 4 === 0 ? 'mr-0' : 'mr-auto' // Last item in a row
              }`}
              key={idx}
            >
              <div className="card-body">
                <div className="flex gap-3 mb-3">
                  {blog.tags.map((tag, tagIdx) => (
                    <Badge key={tagIdx} title={tag} color="primary" />
                  ))}
                </div>
                <h3 className="text-[#101828] font-dm-sans text-xl font-bold leading-[26px] tracking-[0.4px] text-left py-[16px]">{blog.title}</h3>
                <div className="flex gap-5">
                  <Avatar
                    picture={blog.author[0].avatar}
                    name={blog.author[0].name}
                    caption={blog.author[0].role}
                    className="br-theme"
                  />
                  <div className="flex items-center gap-3 text-[#001A3D] br-theme">
                    {blog.created_at}
                  </div>
                  <div className="flex items-center gap-3 text-[#001A3D]">
                    <BsClock /> {blog.read_duration} min to read
                  </div>
                </div>
                <h3 className="text-[#595A5C] font-dm-sans text-base font-medium leading-[24px] tracking-[0.4px] py-[16px] text-left">{blog.description}</h3>
              </div>
            </div>
          ))}
  </div>
  );
};

export const LatestCard = ({ data }) => {
  return (
      <div className="my-1 flex flex-col gap-4">
        {data &&
          data.slice(0, 2).map((blog, idx) => (
            <div
              className={`card bg-white w-full ${
                idx % 4 === 0 ? 'ml-0' : 'ml-auto' // First item in a row
              } ${
                (idx + 1) % 4 === 0 ? 'mr-0' : 'mr-auto' // Last item in a row
              }`}
              key={idx}
            >
              <div className="card-body">
                <div className="flex gap-3 mb-3">
                  {blog.tags.map((tag, tagIdx) => (
                    <Badge key={tagIdx} title={tag} color="primary" />
                  ))}
                </div>
                <h3 className="text-[#101828] font-dm-sans text-xl font-bold leading-[26px] tracking-[0.4px] text-left py-[16px]">{blog.title}</h3>
                <div className="flex gap-5">
                  <Avatar
                    picture={blog.author[0].avatar}
                    name={blog.author[0].name}
                    caption={blog.author[0].role}
                    className="br-theme"
                  />
                  <div className="flex items-center gap-3 text-[#001A3D] br-theme">
                    {blog.created_at}
                  </div>
                  <div className="flex items-center gap-3 text-[#001A3D]">
                    <BsClock /> {blog.read_duration} min to read
                  </div>
                </div>
                <h3 className="text-[#595A5C] font-dm-sans text-base font-medium leading-[24px] tracking-[0.4px] py-[16px] text-left">{blog.description}</h3>
              </div>
            </div>
          ))}
  </div>
  );
};

export const PostCard = ({ data }) => {
  return (
    <>
      <div className="card">
        <img
          style={{
            height: '191px',
            width: '100%',
            background: `url(${data.cover}) lightgray 50% / cover no-repeat`,
          }}
        />
        <div className="card-body">
          <h5>{data.title}</h5>
          <p>{data.description}</p>
          <div className="flex gap-2 my-1">
            {data.tags &&
              data.tags.map((tag, idx) => (
                <Badge key={idx} title={tag} color="primary" />
              ))}
          </div>
          <hr />
          <div className="badge badge-primary mt-3">
            <Avatar
              className="pr-2"
              picture={data.author[0].avatar}
              caption={data.author[0].role}
              name={data.author[0].name}
            />
          </div>
        </div>
      </div>
    </>
  );
};
