import React from 'react';

function BannerSection() {

  return (
    <div className="w-full flex flex-row py-5 pr-20 mb-[50px] items-center">
        <div className="flex flex-col w-[1512px] p-[30px_30px_30px_50px] items-start gap-5 border-[2px] border-[#FFFFFF1F] bg-[#9FB7D7] rounded-r-[20px] text-left">
            <span className="self-stretch text-[#25395C] font-dm-sans text-[16px] font-normal leading-[20px] tracking-[0.4px]">
                 If you need someone to represent you in court or provide expert legal advice, a barrister is the professional you would seek.
            </span>
            <span className="self-stretch text-[#25395C] font-dm-sans text-[16px] font-normal leading-[20px] tracking-[0.4px]">
                They are experts in courtroom advocacy, presenting cases before judges, and providing legal advice on complex matters. Barristers are often called upon when a case goes to trial or needs specialised legal opinions.
            </span>
            <span className="text-[#001A3D] font-dm-sans text-[18px] font-bold leading-[24px] tracking-[0.4px]">
                What should you do?
            </span>
            <span className="self-stretch text-[#25395C] font-dm-sans text-[16px] font-normal leading-[20px] tracking-[0.4px]">
                You can apply all the filters together or just one. Be sure to choose the ones that best match your needs.
            </span>
        </div>
        <button className="ml-auto flex items-center gap-6 text-[#001A3D] text-center font-dm-sans text-base font-medium leading-6 tracking-[0.4px]">
            Clear filters
        </button>
        <button className="ml-auto flex h-[44px] px-[16px] py-[12px] justify-center items-center gap-[10px] rounded-[8px] bg-[#020251] shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] text-[#FFF] text-center font-dm-sans text-base font-medium leading-5 tracking-[0.4px] mr-20">
            Check results
        </button>

    </div>
   );
}

export default BannerSection;