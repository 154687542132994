import React from "react";

const Category = ({ imagePath, title, index, totalItems }) => {
  return (
    <div
      className={`w-1/2 h-[300px] rounded-[16px] border-4 border-white bg-cover bg-center bg-lightgray shadow-md mx-auto
      `}
      style={{ backgroundImage: `url(${imagePath})` }}
    >
      <div className="flex justify-center items-center h-full text-center">
        <h2 className="text-white text-[24px] font-medium leading-[26px] tracking-[0.4px] font-dm-sans">
          {title}
        </h2>
      </div>
    </div>
  );
};

export default Category;
