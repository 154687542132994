import React from 'react';

const itemList = [
  'Legal advice',
  'Conciliation',
  'Probate',
  'Document review',
  'Representation',
];

const ListWithSVG = () => {
  return (
    <div className="w-full h-[200px] overflow-y-auto scrollbar-thin scrollbar-track-scrollbar scrollbar-thumb-[#B8E0DB] scrollbar-track-[#E3E5E4] scrollbar-rounded-[5px] py-5">
      <ul className="space-y-4">
        {itemList.map((item, index) => (
          <li key={index} className="flex items-center space-x-3">
            {/* SVG Icon */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0"
            >
              <g filter="url(#filter0_i_13039_7965)">
                <rect width="16" height="16" rx="2" fill="#B8E0DB" />
              </g>
              <defs>
                <filter
                  id="filter0_i_13039_7965"
                  x="0"
                  y="0"
                  width="16.1"
                  height="16.5"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="0.1" dy="0.5" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                  />
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13039_7965" />
                </filter>
              </defs>
            </svg>

            {/* List Item Text */}
            <span className="text-[#6E7785] font-dm-sans text-base font-normal leading-[20px] tracking-[0.4px]">
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListWithSVG;
