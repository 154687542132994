import React from 'react';
import Category from '../ui-components/Category';

import categories from '../assets/category';  // Assuming categories is an object of images

function SearchedSection() {
    // Define the category data with image paths and titles
    const category_data = [
        { imagePath: categories.category1, title: "Legal Advice" },
        { imagePath: categories.category2, title: "Conciliation" },
        { imagePath: categories.category3, title: "Probate" },
        { imagePath: categories.category4, title: "Document Review" },
    ];

    return (
        <div className="w-full flex flex-col px-20 py-[70px]">
            <div className="text-left text-[#020251] font-dm-sans text-[40px] font-bold leading-normal tracking-normal" style={{ fontFeatureSettings: "'liga' off, 'clig' off" }}>
                Most searched categories
            </div>

            <div className="flex flex-wrap py-[50px] justify-center">
                {category_data.map((category, index) => (
                    <Category
                        key={index}
                        imagePath={category.imagePath}
                        title={category.title}
                        index={index}
                        totalItems={4}
                    />
                ))}
            </div>
        </div>
    );
}

export default SearchedSection;
