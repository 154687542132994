export const socials = {
  light: {
    linkedin: require('./linkedin light.png'),
    mail: require('./mail light.png'),
    twitter: require('./twitter light.png'),
  },
  dark: {
    linkedin: require('./linkedin-dark.png'),
    mail: require('./mail-dark.png'),
    twitter: require('./twitter-dark.png'),
  },
}
