export const Blogs = {
  _01: require('./01.jpeg'),
  _02: require('./02.jpeg'),
  _03: require('./03.jpeg'),
  _04: require('./04.jpeg'),
  _05: require('./05.jpeg'),
  _06: require('./06.jpeg'),
  _07: require('./07.jpeg'),
  _08: require('./08.jpeg'),
  _09: require('./09.jpeg'),
  _10: require('./10.jpeg'),
  _11: require('./11.jpeg'),
  _12: require('./12.jpeg'),
}
