import Slider from "@mui/material/Slider";
import classNames from "classnames";
import { useState } from "react";

const RangeSlider = ({
  min,
  max,
  onChange,
  renderLabel,
  title,
  step,
  disabled,
}) => {
  return (
    <div className="flex flex-col w-[150px] mb-10">
      <span class="text-black font-dm-sans text-sm font-medium leading-normal">
          {title}
      </span>
      <Slider
        defaultValue={0}
        sx={{
          color: 'primary.main', // Track color
          height: 8, // Track height
          '& .MuiSlider-thumb': {
            backgroundColor: '#9FB7D7', // Inner circle color
            width: 15, // Thumb size (inner circle)
            height: 15, // Thumb size (inner circle)
            borderRadius: '50%', // Round shape for inner circle
            position: 'relative',
            zIndex: 1, // Ensure inner circle is on top
            '&:hover': {
              backgroundColor: '#9FB7D7', // Thumb hover color (inner circle)
            },
            '&::before': {
              content: '""', // Empty content for the outer circle
              position: 'absolute',
              top: '-3px', // Position the outer circle
              left: '-3px', // Position the outer circle
              right: '-3px',
              bottom: '-3px',
              width: 21, // Full width and height for the outer circle
              height: 21,
              borderRadius: '50%', // Round shape for outer circle
              border: '1px solid #ffffff', // White border for outer circle
              zIndex: -1, // Ensure the outer circle is below the inner circle
            },
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#FFFFFF', // Rail color
            border: '1px solid #101828', // Rail border (2px solid border with color)
            borderRadius: 4, // Optional: to give rail a rounded border
          },
          '& .MuiSlider-track': {
            backgroundColor: '#3f51b5', // Track color
          },
        }}
      />
      <div className="flex flex-row">
          <span class="text-[#101828] font-dm-sans text-sm font-normal leading-[1.42857] tracking-[0.4px]">
            {min}{renderLabel}
          </span>
          <span class="ml-auto text-[#101828] font-dm-sans text-sm font-normal leading-[1.42857] tracking-[0.4px]">
            {max}{renderLabel}
          </span>
      </div>
    </div>
  );
};

export default RangeSlider;
