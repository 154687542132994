import React from 'react';

const FaqCard = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '24px 20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: '8px',
        border: '0.4px solid #8484F6', // Secondary-Purple-400
        background: 'rgba(255, 255, 255, 0.20)',
        boxShadow: '0px 0.4px 16px 0px rgba(0, 0, 0, 0.08)',
      }}
      className = "my-[20px]"
    >
      {/* Left: Text */}
      <div
        style={{
          color: '#101828', // Grey-scale-Dark-grey
          fontFamily: 'DM Sans',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '24px',
          letterSpacing: '0.4px',
        }}
      >
        {title}
      </div>

      {/* Right: Button (SVG) */}
      <button
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="37" viewBox="0 0 32 37" fill="none">
          <g filter="url(#filter0_d_17383_5819)">
            <path
              d="M7.58594 26.6459C7.58594 28.3416 9.56365 29.2679 10.8663 28.1824L23.7422 17.4525C24.7017 16.6529 24.7017 15.1792 23.7422 14.3796L10.8663 3.64966C9.56366 2.56412 7.58594 3.49042 7.58594 5.1861L7.58594 26.6459Z"
              fill="#00323D"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_17383_5819"
              x="3.58594"
              y="3.18225"
              width="24.875"
              height="33.4675"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17383_5819" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17383_5819" result="shape" />
            </filter>
          </defs>
        </svg>
      </button>
    </div>
  );
};

export default FaqCard;
