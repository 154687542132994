import React from 'react';
import { Avatars } from '../assets/avatars'
import { flags } from '../assets/flags'
import { BsInfoCircle, BsX, BsXLg } from 'react-icons/bs'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { socials } from '../assets/socials'
import { Brand } from '../assets/brands'

function Footer() {
  return (
    // <div className="absolute top-[0px] left-0 w-full max-w-[1920px] h-[82px] bg-[#9FB7D7] border border-white/12 flex flex-col items-start p-[20px_20px_20px_50px] gap-[40px] box-border">
    //   <h1 className="text-blue-custom font-sans font-bold text-[32px] leading-[42px]">
    //     Get a barrister
    //   </h1>
    // </div>

    <footer className="bg-[#001A3D]">
       <div className= "flex flex-col w-full">
            <div className="bg-gradient-to-r from-[#3E75BC] to-[#65C2D7] flex flex-row items-start gap-10 p-16 self-stretch justify-center">
                <div className ="flex flex-col" >
                    <div class="text-[#001A3D] text-sm font-normal leading-4 tracking-[0.4px] py-1">A brand delivered by</div>
                    <div className = "flex flex-row">
                        <img src= {Brand.logo} alt="description" className="w-[24px] h-[24px] flex justify-center items-center shrink-0 mr-2" />
                        <span className = "text-[#122D5C] font-bold">Legal Utopia</span>
                    </div>
                </div>
                <div className="justify-center items-center mx-auto">
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    Who we are
                    </a>
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    FAQs
                    </a>
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    Contact us
                    </a>
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    Legal
                    </a>
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    Privacy policy
                    </a>
                    <a href="#" className="text-[#101828] text-center font-bold text-lg leading-6 tracking-[0.4px] pr-[32px]">
                    Terms and conditions
                    </a>
                </div>
                <div className='img flex gap-5 ml-auto'>
                    <img src={socials.dark.linkedin} alt='' />
                    <img src={socials.dark.twitter} alt='' />
                    <img src={socials.dark.mail} alt='' />
                </div>
            </div>
            <div className="text-white text-justify font-normal text-sm leading-5 tracking-[0.4px] p-16 justify-center items-center self-stretch">
            Legal Utopia, Legal Utopia - The A.I Way and LegalCrowd are the trademarks and trading names of Legal Utopia Limited, a company registered in England and Wales under company number 10909418 operating from and registered address Legal Utopia Limited 86-90 Paul Street London EC2A 4NE. (C) Legal Utopia Limited 2017-2024. Legal Utopia is patent-pending in the United Kingdom, United States of America and Singapore. All Rights Reserved. Google Play and the Google Play logo are trademarks of Google LLC.  iPhone and Apple are trademarks of Apple Inc., registered in the U.S. and other countries. Apple App Store logos are trademarks of Apple Inc., registered in the U.S. and other countries. Legal Utopia Limited is registered for VAT under registration number: GB 404 391 911.
            </div>
        </div>
    </footer>


  );
}

export default Footer;
